import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { HttpClient } from '@angular/common/http';

declare var angular: angular.IAngularStatic;

type Tracker = {
  send: (
    hitType: string,
    category: string,
    action: string,
    label?: string
  ) => void;
};

declare const ga: {
  (...args: any[]): () => void;
  getAll: () => Tracker[];
};

const has = Object.prototype.hasOwnProperty;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsServiceService {
    private enabled: boolean = false;

    constructor(http: HttpClient) {
        // initialize configs
        http.get(`/analyticsConfig`).subscribe(
            (res: any) => {
              if(res && res.enabled) {
                this.enabled = res.enabled;
                console.log('Analytics config enabled: ' + this.enabled);
              }
            },
            (err: any) => {
                console.error('Could not get Google analytics configs. ' + err.message);
            });
    }

  logCustomEvent(
    eventCategory: string,
    eventAction: string,
    eventLabel?: string
  ) {
        if (this.enabled) {
            (<any>window).ga('send', 'event', {
                eventCategory, eventLabel, eventAction, eventValue: 1
            });
        }
  }

  logPageView(url: string) {
    if (this.enabled) {
       ga(() => {
      if (has.call(window, "ga")) {
        ga("set", "page", url);
        ga("send", "pageview");
      }
    });
  }
  }

  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string ,
    eventValue: number ){
      if (this.enabled) {
        ga('event', eventName, {
          eventCategory: eventCategory,
          eventLabel: eventLabel,
          eventAction: eventAction,
          eventValue: eventValue
        })
      }  
   }
}

angular.module('comcast.common.communication').factory('GoogleAnalyticsServiceService', downgradeInjectable(GoogleAnalyticsServiceService));

